import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Curation from '../views/Curation.vue'
import Login from '../views/Login.vue'
import store from '../store'
const routes = [
	{
		path: '/',
		name: 'Curation',
		beforeEnter: requireAuth,
		component: Curation
	},
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	beforeEnter: requireAuth,
	// 	component: Home
	// },
	// {
	// 	path: '/curation',
	// 	name: 'Curation',
	// 	beforeEnter: requireAuth,
	// 	component: Curation
	// },
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/about',
		name: 'About',
		beforeEnter: requireAuth,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	}
]

const router = createRouter({
	mode: "history",
	history: createWebHistory(process.env.BASE_URL),
	routes
})

function requireAuth (to, from, next){
	store.dispatch('getCredentials').then(test => {
		if (!test) {
			next({
				path: '/login',
				query: {redirect: to.fullPath}
			})
		}
		else {
			next();
		}
	});
}

export default router
