
<!-- <template src="./login.html"></template> -->
<template>
	<div class="container">		
			
		<div class="row">
			<div class="col-6 offset-3">
				<h4 class="text-center">Welcome to {{siteTitle}}</h4>
				
				<q-card class="my-card">
					<q-card-section>
						<div class="text-h6 text-center">Login</div>
					</q-card-section>
					<q-card-section>
						<form v-on:submit.prevent="login()">
							<div class="q-pa-md">
								<div class="q-gutter-md" >
									<q-input v-model="user.username" class="secondary" outlined type="text" label="login" />
									<q-input v-model="user.password" outlined type="password" label="Password" />
									<q-btn outline color="primary" label="Login" type="submit"/>
									<p class="text-center text-negative" v-if="warning.status"><em>{{warning.message}}</em></p>
								</div>
							</div> 
						</form>
					</q-card-section>
				</q-card>
					
				<div class="card" v-if='!show_ga'>

					<!-- <form v-on:submit.prevent="loginUser()">
						<div class="form-group row" :class="{ 'has-error': errors.has('username') }">
							<div class="col-3"><label class="float-right" for="username">Username</label> </div>
							<div class="col-6"> <input type="text" v-validate="'required'" name="username" id="username" v-model="user.username" class="form-control" autofocus></div>
							<div class='col-3'><small v-show="errors.has('username')"> {{ errors.first('username') }}</small></div>
						</div>
						<div class="form-group row" :class="{ 'has-error': errors.has('password') }">
							<div class="col-3"> <label class="float-right" for="username">Password</label> </div>
							<div class="col-6"><input type="password" v-validate="'required'" name="password" id="password" v-model="user.password" class="form-control"></div>
							<div class='col-3'><small v-show="errors.has('password')"> {{ errors.first('password') }}</small></div>
						</div>

						<div class="form-group row justify-content-center">
							<button type="submit" class="btn btn-primary" :disabled='errors.any()'>Submit</button>
						</div>
					</form> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {siteTitle} from '../app_config';
// import {Bus} from '@/bus'

export default {
	name: 'login',
	data () {
		return {
			siteTitle:siteTitle,
			warning:{status:false,message:null},
			user:{username:'',password:'',ga_code: '',qr_img_src: '',user_id: '',ga_status: ''},
			authorized_user:{username:'hippocrates_2022',password:'FjV->m3GG2Z(jHJF7.uq'},
			show_ga: false
		}
	},
	watch:{
		"user.password" (){
			this.warning={status:false,message:null};
		},
		"user.username" (){
			this.warning={status:false,message:null};
		},
	},
	methods:{
		login(){
			if(this.user.username != this.authorized_user.username || this.user.password != this.authorized_user.password){
				this.warning = {status:true,message:'Wrong login or password'};
				// this.$q.notify('Some other message')
				// $q.notify({ message: 'Jim pinged you.', color: 'purple' });

			}
			else{
				localStorage.setItem('currentUser',JSON.stringify(this.authorized_user));	
				this.$router.push('/');
			}
		}
	},
	created (){
	}
}
</script>

	<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login_container{
	margin-top:100px;
}
form{
	margin:20px 0;
}
.card{
	margin:20px 0;
}
</style>
		










