
// initial state
const state = {

}

// getters
const getters = {

}

// actions
const actions = {
	getCredentials(){
		let user = localStorage.getItem('currentUser');
		return user;
	}

}

// mutations
const mutations = {

}

export default {
	state,
	getters,
	actions,
	mutations
}