<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy" >
      <q-toolbar class="bg-secondary">
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" />

        <q-toolbar-title class="flex-center"> <HippoImage /> </q-toolbar-title>
				<q-separator dark vertical />
				<q-btn stretch flat label="Home" @click="goTo()"/>
				<q-separator dark vertical />
				<!-- <q-btn stretch flat label="Curation" @click="goTo('curation')" />
				<q-separator dark vertical /> -->
				<q-btn stretch flat label="About" @click="goTo('about')" />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" :width="200" show-if-above bordered class="bg-grey-2" >

      <q-list>
        <q-item-label header>Essential Links</q-item-label>
        <q-item clickable tag="a" target="_blank" href="https://www.hippocrates-imi.eu/">
          <q-item-section avatar> <q-icon name="school" /> </q-item-section>
          <q-item-section>
					
            <q-item-label>Hippocrates IMI</q-item-label>
            <q-item-label caption>official site</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" target="_blank" href="https://athena.ohdsi.org/search-terms/terms">
          <q-item-section avatar> <q-icon name="school" /> </q-item-section>
          <q-item-section>
            <q-item-label>Athena</q-item-label>
          </q-item-section>
        </q-item>

      </q-list>
    </q-drawer>

    <q-page-container>
    <!-- <img alt="Quasar logo" src="assets/logo.svg" style="width: 200px; height: 200px"> -->
		

			<router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
// import { ref } from 'vue'
// import HelloWorld from './components/HelloWorld.vue'
import HippoImage from './components/HippoImage.vue'

export default {
	name: 'LayoutDefault',

	components: {
		HippoImage
	},
	data () {
		return {
			leftDrawerOpen:false,
			version:"0.0"
		}
	},
	methods:{
		goTo(cas){
			let rout = (cas) ? "/"+cas : "/";
			this.$router.push(rout);
		}
	},
	created(){
		document.title = "Hippocrates";
		const tag = document.createElement('meta')
		tag.setAttribute('name','robots')
		tag.setAttribute('content','none')
		document.head.appendChild(tag)
		
	}
}
</script>
