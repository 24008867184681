import {HTTP} from '@/router/http';

// initial state
const state = {

}

// getters
const getters = {

}

// actions
const actions = {
	search (context,params) {
		let term = params.term;
		return new Promise((resolve) => {
			let pass_params = {page:params.table.page,perPage:params.table.perPage};
			let fields = ['vocabulary_id','domain_id','perfect','exclude'];
			_.forEach(fields, function(f){
				if(params.filters[f]){
					if (f=='vocabulary_id' || f=='domain_id'){ pass_params[f] = params.filters[f].join(',') ; }
					else{ pass_params[f] = params.filters[f]; }
				}
			}) 

			HTTP.get('/search/'+term,{params:pass_params}).then(function(response){
				var data = response.data;
				resolve(data);
			});
		});
	},

}

// mutations
const mutations = {

}

export default {
	state,
	getters,
	actions,
	mutations
}