import { createStore } from 'vuex'
import search from './modules/search'
import users from './modules/users'

export default createStore({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		search,
		users
	}
})

