<template>
	<span>
		
		<img class="q-pt-sm q-mr-sm" alt="SIB logo" src="../assets/sib_logo_small.gif" style="width:58px;height:48px">
		<img class="q-pt-sm" alt="Hippocrates logo" src="../assets/hippocrates_logo.png" style="width: 135px; height: 48px">
	</span>
</template>

<style>
</style>

<script>
export default {
	name: 'HippoImage'
}
</script>
