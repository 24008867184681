<template>
	<div>

		<h4 class="text-center mb-3">Welcome to HIPPOCRATES <br>browser for standardised vocabularies</h4>
		<div class="row mt-4">
			<div class="col-sm-6 offset-3">
		
				<form @submit.prevent="searchAction(true)" class="q-mb-sm">
					<q-input v-model="search" outlined placeholder="Search" >
						<template v-slot:append> <q-icon name="search" @click="searchAction(true)"/> </template>
					</q-input>
					<template v-if="filters.advanced">
						<q-checkbox v-model="filters.perfect" label="Perfect match" color="teal" />
						<q-checkbox v-model="filters.exclude_status" label="Exclude words" color="teal" />
						<q-input v-model="filters.exclude" class="q-mt-sm" v-if="filters.exclude_status" outlined placeholder="words to exclude" ></q-input>
					</template>
				</form>
				<div class="q-mt-sm">
					<q-btn outline rounded label="Reset" @click="reset()"/>
					<q-btn outline rounded color="grey-7" class="q-ml-sm" :label="(filters.advanced) ? 'Simple search' : 'Advanced search'" @click="advancedAction()"/>
					<q-btn outline rounded color="secondary" class="float-right q-ml-sm" label="Search" @click="searchAction(true)"/>
					<q-btn outline rounded color="secondary" class="float-right" label="Example" @click="setExample()"/>
				</div>
				<p v-if="warning" class="text-center q-mt-sm" style="color:#DC686A">{{warning}}</p>
			</div>
		</div>
	<!-- </div> -->


	<div class = 'container-perso'>

		<div v-if="state.loaded">
			<div class="row" v-if="results.total">
				<div class="col-12">
					<h6 class="text-center" style="margin:10px"><strong>{{results.total}} result<span v-if="results.total>1">s</span></strong></h6>
				</div>

				<table class="table table-sm table-center table-hover">
					<thead>
						<tr>
							<th></th>
							<th class="text-nowrap">Concept ID</th>
							<th class="text-nowrap">Concept name</th>
							<th>Synonym</th>
							<th class="text-nowrap" style="width:15%">Vocabulary ID</th>
							<th class="text-nowrap" style="width:15%">Domain ID</th>
						</tr>
						<tr>
							<th colspan="4"></th>
							<th>
								<q-select rounded outlined v-model="filters.vocabulary_id" multiple :options="options.vocabulary_id" :dense='true'  />
							</th>
							<th>
								<q-select rounded outlined v-model="filters.domain_id" multiple :options="options.domain_id" :dense='true' />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="res in results.data" :key="res.id">
							<td>
								<q-badge v-if="res.standard_terminology=='HIPPOCRATES'" color="blue"> H
									<q-tooltip> Standard terminology {{res.standard_terminology}} </q-tooltip>
								</q-badge>
								<q-badge v-if="res.standard_terminology=='BIOMAP'" color="blue"> B
									<q-tooltip> Standard terminology {{res.standard_terminology}} </q-tooltip>
								</q-badge>
							</td>
							<td><a target="_blank" :href="'https://athena.ohdsi.org/search-terms/terms/'+res.concept_id">{{res.concept_id}}</a></td>
							<td><span v-html="res.concept_name"></span></td>
							<td><span v-html="res.concept_synonym_name"></span></td>
							<td><span v-html="res.vocabulary_id"></span></td>
							<td><span v-html="res.domain_id"></span></td>
						</tr>
					</tbody>
				</table>

				<!-- <span class="mr-2 mt-2">Nb per page</span> <select class="form-control mr-2" style="display:inline-flex;width:75px" v-model="table.perPage"><option v-for="op in table.perPageOptions" :key="op">{{op}}</option></select> -->
				<!-- <b-pagination v-model="table.page" :total-rows="results.total" :per-page="table.perPage" aria-controls="my-table" ></b-pagination> -->
				<div class="q-pa-lg flex flex-center">
					<!-- <q-select outlined v-model="table.perPage" :options="table.perPageOptions" label="Nb per page" /> -->
					<span class="q-mr-sm">
						{{table.perPage}} results per page  -  
					</span>
					<q-pagination v-model="table.page" color='teal' :max="table.maxPage" :max-pages="6" direction-links boundary-links icon-first="skip_previous" icon-last="skip_next" icon-prev="fast_rewind" icon-next="fast_forward" />
				</div>
			</div>
			<div v-else>
				<p class="text-center mt-3"> <em>No result found</em></p>
			</div>
		</div>
	</div>
	</div>
</template>

<script>	
// import { siteTitle } from '../app_config'
import exData from "../data/ex2.json";
export default {
	name: 'home',
	data () {
		return {
			search:'',
			test:'<span class="high">smok</span>e from co',
			exData:exData,
			// advanced:{status:false,perfect:false,exclude:null,exclude_status:false},
			results:{total:null,data:[]},
			state:{loading:false,loaded:false},
			table:{page:1,perPage:50,perPageOptions:[25,50,100,200],maxPage:1},
			data:[],
			warning:null,
			filterTimeout:null,
			filters:{vocabulary_id:null,domain_id:null,advanced:false,perfect:false,exclude:null,exclude_status:false},
			options:{vocabulary_id:null,domain_id:null},
			
			columns : [
				{ name: 'concept_code', align: 'center', label: 'Concept code', field: 'concept_id', sortable: true },
				{ name: 'concept_id', label: 'Concept ID', field: 'concept_id', sortable: true },
				{ name: 'synonym', label: 'Synonym', field: 'synonym', sortable: true },
				{ name: 'concept_name', label: 'Concept name', field: 'concept_name' },
				{ name: 'vocabulary_id', label: 'Vocabulary ID', field: 'vocabulary_id', sortable: true },
				{ name: 'domain_id', label: 'Domain ID', field: 'domain_id', sortable: true },
			]
		}
	},
	watch:{
		"table.page" (to,from){
			if (to!=from){
				this.searchAction();
			}
		},
		"table.perPage" (to,from){
			if (to!=from){
				// this.$set(this.table,'page',1);
				this.searchAction(true);
			}
		},
		
		"filters": {
			// handler: function(){
			handler: function(){
				var vm = this
				if (vm.filterTimeout) { clearTimeout(vm.filterTimeout) }
				vm.filterTimeout = setTimeout(() => {
					// let fields = ['vocabulary_id','domain_id'];
					// _.forEach(fields,function(f){
					// if (from[f] != to[f]){
					vm.searchAction(true);
					// }
					// })
				},300);
			},
			deep: true
		},
		
	},
	methods:{
		advancedAction(){
			this.filters.advanced = !this.filters.advanced;
		},
		reset(){
			this.search = null;
			this.table.page = 1;
			this.warning = null;
			this.state.loaded = false;
			this.filters={vocabulary_id:null,domain_id:null,advanced:false,perfect:false,exclude:null,exclude_status:false};
			this.results = {total:null,data:[]};
		},
		setExample(){
			let _this = this;
			_this.search = 'smok';
			_this.searchAction(true);
		},

		searchAction(init){
			// console.info("search",this.search);
			let _this = this;
			this.warning = null;
			if(!_this.search) return;
			else if(_this.search.length<3){
				this.warning = 'Please enter at least 3 characters';
				return;
			}
			_this.results = {total:null,data:[]};
			if (init) this.table.page = 1;
			_this.state.loading = true;
			_this.state.loaded = false;	
			// console.info(this.filters);			
			this.$store.dispatch('search',{term:this.search,table:this.table,filters:this.filters}).then(function(data){
				// let data = exData;
				_this.state.loading = false;
				_this.state.loaded = true;
				_this.table.maxPage = Math.round(data.total/_this.table.perPage);
				let option_keys = _.keys(_this.options);
				_.forEach(option_keys,function(o){
					_this.options[o] = data.categories[o];
				})
				_this.results = {total:data.total,data:data.data};
			})
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	
	.container-perso{
		margin:2% 7%;
	}
h1, h2 {
	font-weight: normal;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}

nowrap th{
	white-space:nowrap;
}
</style>
