export const serverURL = (process.env.NODE_ENV === 'development') ? 'http://vikm1.vital-it.ch:8081/api/index.php/' : '/api/index.php/'

export const siteTitle = 'Hippocrates';

export const loginType = 'local'; // local or auth0

export const multilang = false

export const getHeader = function () {
	const headers = {
		'Accept':'application/json',
		// 'Authorization':'Bearer' + tokenData.access_token
	}
	return headers
}