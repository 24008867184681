import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import store from './store'
// import lodash from 'lodash'
import _ from 'lodash';

import './styles/table.css'
// import { vueKeycloak } from '@baloise/vue-keycloak'

createApp(App)
	.use(store)
	// .use(vueKeycloak, {
	// 	initOptions: {
	// 		flow: 'standard', // default
	// 		checkLoginIframe: false, // default
	// 		onLoad: 'login-required', // default
	// 	},
	// 	config: {
	// 		url: process.env.VUE_APP_URL_KEYCLOAK + '/auth',
	// 		realm: process.env.VUE_APP_REALM_KEYCLOAK ,
	// 		clientId: process.env.VUE_APP_CLIENTID
	// 		// url: 'http://localhost:8080/auth',
	// 		// realm: 'Hippocrates' ,
	// 		// clientId: 'hippocrates-sp'
	// 	}
	// })
	.use(router)
	.use(Quasar, quasarUserOptions,_)
	.mount('#app')

